import permissions from "@/store/permissions";

export default [
  {
    title: "Dashboard",
    route: "home",
    icon: "homeIcon",
  },
  {
    title: "News",
    route: "news",
    icon: "docIcon",
    permission: "news.list",
  },
  {
    title: "My Cases",
    icon: "docIcon",
    permission: "case-instance.list",
    children: [
      {
        title: "Active",
        route: "my-cases-active",
        permission: "case-instance.list",
      },
      {
        title: "Flagged",
        route: "my-cases-flagged",
        permission: "case-instance.list",
      },
      {
        title: "Finished",
        route: "my-cases-finished",
        permission: "case-instance.list",
      },
      {
        title: "Trash",
        route: "my-cases-trash",
        permission: "case-instance.list",
      },
    ],
  },
  {
    title: "My Info",
    icon: "userIcon",
    children: [
      {
        title: "Account",
        route: "account",
        permission: "user.list",
      },
      // {
      //   title: "Storage",
      //   route: "storage",
      // },
      {
        title: "Payment History",
        route: "payment-history",
        permission: "payment-history.list",
      },
      {
        title: "Invoices",
        route: "invoices",
        permission: "invoice.list",
      },
      // {
      //   title: "FAQ",
      //   route: "faq",
      // },
    ],
  },
  {
    title: "Credits",
    route: "credits",
    icon: "creditsIcon",
    permission: "package.list",
  },
  {
    title: "Ticket System",
    route: "tickets",
    icon: "ticketIcon",
    permission: "ticket.list",
  },
  // {
  //   title: "FAQ",
  //   route: "faq",
  //   icon: "InfoIcon",
  // }
  // {
  //   title: 'Packages',
  //   route: 'packages',
  //   icon: 'ShoppingCartIcon',
  // },
  // {
  //   title: 'Invoices',
  //   route: 'invoices',
  //   icon: 'FileTextIcon',
  // },
  // {
  //   header: "Storage",
  // },
  // {
  //   title: "Case List",
  //   route: "case-list",
  //   icon: "ListIcon",
  // },
  // {
  //   labels: ["cloud-storage"],
  //   title: 'Cloud Storage',
  //   route: 'cloud-storage',
  //   icon: 'HardDriveIcon',
  // },
  // {
  //   header: "Extensions",
  // },
  // {
  //   title: "Reconstruction AI",
  //   route: "2d-to-3d",
  //   icon: "CpuIcon",
  //   labels: ["2d3d-extension"],
  // },
];
