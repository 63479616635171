<template>
  <div>
    <PageHeader :items="items" />
    <div class="mycases create-case">
      <div class="pageheader">
        <h2>{{ $t("Active Cases") }}: {{ totalRecords }}</h2>
        <div class="pageheader-right">
          <div class="d-flex align-items-center gap-1 filter-by-color">
            <p>{{ $t("Status") }}</p>
            <b-form-group class="mb-0">
              <select class="form-control" v-model="form.caseStatus">
                <option
                  v-for="option in caseStatusOptions"
                  :value="option.value"
                  :key="option.value"
                >
                  {{ option.text }}
                </option>
              </select>
            </b-form-group>
          </div>
          <div class="search">
            <div class="icon">
              <ElementIcon v-bind:icon="'searchIcon'" />
            </div>
            <input
              type="text"
              v-model="form.search"
              :placeholder="$t('Search for...')"
            />
          </div>
          <div class="divider"></div>
          <div class="d-flex align-items-center gap-1 filter-by-color">
            <p>{{ $t("Filter by") }}</p>
            <b-form-group class="m-0">
              <select class="form-control" v-model="form.selectedFilter">
                <option value="all">{{ $t("All") }}</option>
                <option
                  v-for="flag in existingFlags"
                  :key="flag.id"
                  :value="flag.id"
                  :style="{ color: flag.color }"
                >
                  {{ flag.name }}
                </option>
              </select>
            </b-form-group>
          </div>
          <div class="d-flex align-items-center gap-1 filter-by-color">
            <p>{{ $t("At least") }}</p>
            <StarRating
              :value="filterRatingValue"
              @setRatingValue="setFilterRatingValue"
            />
          </div>
        </div>
      </div>

      <!-- Cases Display -->
      <div class="card case-m-card">
        <div class="card-body">
          <div
            class="d-flex align-items-center justify-content-between mb-2"
            v-if="rows.length > 0"
          >
            <div class="checkbox-s-group">
              <input
                type="checkbox"
                class="checkbox-input"
                id="selectAll"
                v-model="selectAll"
                @change="toggleSelectAll"
              />
              <label for="selectAll" class="checkbox-label m-0">
                {{ $t("Select All Cases") }}
              </label>
            </div>
          </div>
          <b-row class="case-m">
            <b-col
              lg="6"
              class="mb-2"
              v-for="(caseItem, index) in rows"
              :key="index"
              v-if="rows.length > 0"
            >
              <label class="w-100 h-100" :for="'case-' + caseItem.id">
                <div class="case-card">
                  <div
                    class="d-flex align-items-center justify-content-between mb-2"
                  >
                    <div class="d-flex align-items-center">
                      <div class="checkbox-s-group">
                        <input
                          type="checkbox"
                          class="checkbox-input"
                          :id="'case-' + caseItem.id"
                          v-model="selectedCases"
                          :value="caseItem.id"
                        />
                        <label
                          :for="'case-' + caseItem.id"
                          class="checkbox-label m-0"
                        ></label>
                      </div>
                      <h4>
                        {{ $t("Case") }}:
                        {{ caseItem.name }}
                      </h4>
                    </div>
                    <div class="d-flex align-items-center gap-2">
                      <p class="status-tag" v-if="caseItem.isConnected === 1">
                        {{ $t("Connected") }}
                      </p>
                      <p
                        class="status-tag"
                        :class="caseItem.caseStatus"
                        :title="hoverMessage(caseItem)"
                      >
                        {{ $t(caseItem.caseStatus) }}
                      </p>
                      <h4>
                        {{ dateFormatter(caseItem.creation_date) }}
                      </h4>
                    </div>
                  </div>
                  <div
                    class="d-flex align-items-center justify-content-between"
                  >
                    <div class="case-left">
                      <div
                        class="failed"
                        v-if="caseItem.caseStatus == 'failed'"
                      >
                        <ElementIcon v-bind:icon="'xIcon'" />
                      </div>
                      <div
                        class="failed"
                        v-else-if="caseItem.caseStatus == 'unsupported'"
                      >
                        <ElementIcon v-bind:icon="'xIcon'" />
                      </div>
                      <span
                        class="hour-glass"
                        v-else-if="
                          caseItem.caseStatus == 'queued' ||
                          caseItem.caseStatus == 'in-progress'
                        "
                        ><img src="@/assets/images/svg/hourglass.gif" alt=""
                      /></span>
                      <div class="" v-else>
                        <img
                          v-if="
                            caseItem.results && caseItem.results['upper_image']
                          "
                          :src="caseItem.upperImageSrc"
                          alt=""
                        />
                        <div
                          v-if="
                            caseItem.results && caseItem.results['upper_image']
                          "
                          class="d-flex justify-content-center"
                        >
                          <StarRating
                            :key="caseItem.id"
                            :value="
                              (caseItem.ratings &&
                                caseItem.ratings.upper_case_rating) ||
                              0
                            "
                            @setRatingValue="
                              setRatingValue($event, caseItem.id, 'upper')
                            "
                          />
                        </div>
                        <h4
                          v-if="
                            caseItem.results && caseItem.results['upper_image']
                          "
                          class="text-center mt-2"
                        >
                          {{ $t("Upper") }}
                        </h4>
                      </div>
                    </div>
                    <div class="case-right">
                      <div
                        class="failed"
                        v-if="caseItem.caseStatus == 'failed'"
                      >
                        <ElementIcon v-bind:icon="'xIcon'" />
                      </div>
                      <div
                        class="failed"
                        v-else-if="caseItem.caseStatus == 'unsupported'"
                      >
                        <ElementIcon v-bind:icon="'xIcon'" />
                      </div>
                      <span
                        class="hour-glass"
                        v-else-if="
                          caseItem.caseStatus == 'queued' ||
                          caseItem.caseStatus == 'in-progress'
                        "
                        ><img src="@/assets/images/svg/hourglass.gif" alt=""
                      /></span>
                      <div class="" v-else>
                        <img
                          v-if="
                            caseItem.results && caseItem.results['lower_image']
                          "
                          :src="caseItem.lowerImageSrc"
                          alt=""
                        />
                        <div
                          v-if="
                            caseItem.results && caseItem.results['lower_image']
                          "
                          class="d-flex justify-content-center"
                        >
                          <StarRating
                            :key="caseItem.id"
                            :value="
                              (caseItem.ratings &&
                                caseItem.ratings.lower_case_rating) ||
                              0
                            "
                            @setRatingValue="
                              setRatingValue($event, caseItem.id, 'lower')
                            "
                          />
                        </div>
                        <h4
                          v-if="
                            caseItem.results && caseItem.results['lower_image']
                          "
                          class="text-center mt-2"
                        >
                          {{ $t("Lower") }}
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div
                    class="d-flex align-items-center justify-content-between"
                  >
                    <div
                      class="tag"
                      :style="{
                        backgroundColor: caseItem.tagColor,
                      }"
                      @click="openModal(caseItem)"
                      :title="caseItem.flag ? caseItem.flag.name : ''"
                    ></div>
                    <div class="action">
                      <b-nav-item-dropdown
                        right
                        toggle-class="d-flex align-items-center"
                        class=""
                      >
                        <template #button-content>
                          <div class="">
                            <ElementIcon v-bind:icon="'barVerticalIcon'" />
                          </div>
                        </template>

                        <b-dropdown-item
                          @click="
                            handlePreviewClick(
                              caseItem.files['upper'],
                              caseItem.files['lower']
                            )
                          "
                          link-class="d-flex align-items-center"
                        >
                          <feather-icon
                            size="16"
                            icon="InfoIcon"
                            class="mr-50"
                          />

                          <span>
                            {{ $t("Preview Original") }}
                          </span>
                        </b-dropdown-item>
                        <b-dropdown-item
                          link-class="d-flex align-items-center"
                          @click="
                            downloadFiles([
                              caseItem.files['upper'],
                              caseItem.files['lower'],
                            ])
                          "
                        >
                          <feather-icon
                            size="16"
                            icon="DownloadIcon"
                            class="mr-50"
                          />

                          <span>
                            {{ $t("Download Original") }}
                          </span>
                        </b-dropdown-item>
                        <!-- @click="
                            handlePreviewClick(
                              caseItem.results['upper'],
                              caseItem.results['lower']
                            )
                          " -->
                        <div v-if="caseItem.results">
                          <b-dropdown-item
                            :disabled="caseItem.caseStatus === 'in-approval'"
                            @click="
                              handlePreviewResultClick([
                                caseItem.results['upper_rotated'] ||
                                  caseItem.results['upper'],
                                caseItem.results['lower_rotated'] ||
                                  caseItem.results['lower'],
                              ])
                            "
                            link-class="d-flex align-items-center"
                            :class="
                              caseItem.caseStatus === 'in-approval'
                                ? 'cursor-not-allowed'
                                : ''
                            "
                            v-b-tooltip.top="
                              $t(
                                'Case first needs to be approved by dentaltwin'
                              )
                            "
                          >
                            <feather-icon
                              size="16"
                              icon="InfoIcon"
                              class="mr-50"
                            />

                            <span>
                              {{ $t("Preview Result") }}
                            </span>
                          </b-dropdown-item>
                          <b-dropdown-item
                            :disabled="caseItem.caseStatus === 'in-approval'"
                            @click="
                              downloadFiles([
                                caseItem.results['upper_rotated'] ||
                                  caseItem.results['upper'],
                                caseItem.results['lower_rotated'] ||
                                  caseItem.results['lower'],
                              ])
                            "
                            :class="
                              caseItem.caseStatus === 'in-approval'
                                ? 'cursor-not-allowed'
                                : ''
                            "
                            v-b-tooltip.top="
                              $t(
                                'Case first needs to be approved by dentaltwin'
                              )
                            "
                            link-class="d-flex align-items-center"
                          >
                            <feather-icon
                              size="16"
                              icon="DownloadIcon"
                              class="mr-50"
                            />

                            <span>
                              {{ $t("Download Result") }}
                            </span>
                          </b-dropdown-item>
                          <b-dropdown-item
                            v-if="$hasRole('admin')"
                            @click="
                              handlePreviewResultClick([
                                caseItem.results['upper'],
                                caseItem.results['lower'],
                              ])
                            "
                            link-class="d-flex align-items-center"
                          >
                            <feather-icon
                              size="16"
                              icon="InfoIcon"
                              class="mr-50"
                            />

                            <span>
                              {{ $t("Preview Without Rotation") }}
                            </span>
                          </b-dropdown-item>
                          <b-dropdown-item
                            v-if="$hasRole('admin')"
                            @click="
                              downloadFiles([
                                caseItem.results['upper'],
                                caseItem.results['lower'],
                              ])
                            "
                            link-class="d-flex align-items-center"
                          >
                            <feather-icon
                              size="16"
                              icon="DownloadIcon"
                              class="mr-50"
                            />

                            <span>
                              {{ $t("Download Without Rotation") }}
                            </span>
                          </b-dropdown-item>
                        </div>
                        <b-dropdown-item
                          @click="updateStatus(caseItem.id, 'finished')"
                          link-class="d-flex align-items-center"
                        >
                          <feather-icon
                            size="16"
                            icon="CheckIcon"
                            class="mr-50"
                          />

                          <span>
                            {{ $t("Finished") }}
                          </span>
                        </b-dropdown-item>
                        <b-dropdown-item
                          @click="replicateCase(caseItem.id, caseItem.files)"
                          link-class="d-flex align-items-center"
                        >
                          <feather-icon
                            size="16"
                            icon="CopyIcon"
                            class="mr-50"
                          />

                          <span>
                            {{ $t("Recalculate") }}
                          </span>
                        </b-dropdown-item>
                        <b-dropdown-item
                          v-if="$can(`ticket.create`)"
                          link-class="d-flex align-items-center"
                          @click="reportCase(caseItem)"
                        >
                          <feather-icon
                            size="16"
                            icon="FlagIcon"
                            class="mr-50"
                          />
                          <span>
                            {{ $t("Report Case") }}
                          </span>
                        </b-dropdown-item>
                        <b-dropdown-item
                          link-class="d-flex align-items-center"
                          @click="updateStatus(caseItem.id, 'trash')"
                        >
                          <feather-icon
                            size="16"
                            icon="TrashIcon"
                            class="mr-50"
                          />
                          <span>
                            {{ $t("Delete") }}
                          </span>
                        </b-dropdown-item>
                      </b-nav-item-dropdown>
                    </div>
                  </div>
                </div>
              </label>
            </b-col>
            <b-col v-if="rows.length === 0" cols="12" class="text-center"
              ><p>{{ $t("No active cases found") }}.</p></b-col
            >
          </b-row>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-center">
        <!-- Pagination -->
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRecords"
          :per-page="perPage"
          @input="(value) => onPageChange({ currentPage: value })"
          aria-controls="case-list"
        ></b-pagination>
      </div>
      <CookieButton>
        <template #cookieBtns>
          <button
            :disabled="selectedCases.length == 0"
            class="btn btn-secondary mr-1"
            @click="downloadCaseFiles()"
          >
            {{ $t("Download") }}
          </button>
          <button
            :disabled="selectedCases.length == 0"
            class="btn btn-secondary mr-1"
            @click="updateStatuses('finished')"
          >
            {{ $t("Finished") }}
          </button>
          <button
            @click="updateStatuses('trash')"
            :disabled="selectedCases.length == 0"
            class="btn btn-secondary"
          >
            {{ $t("Delete") }}
          </button>
        </template>
      </CookieButton>
      <!-- B-Modal for editing tag -->
      <b-modal
        id="edit-tag-modal"
        v-model="showModal"
        title="Edit Flag"
        centered
        size="md"
        hide-footer
      >
        <b-form @submit.prevent="updateCase" class="modal-form">
          <validation-observer ref="simpleRules">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Flag"
                rules="required"
              >
                <label class="form-label input-label" for="flag"
                  >{{ $t("Flag") }}&nbsp;<span style="color: #f00">*</span>
                </label>
                <multi-select
                  v-model="tag.flag"
                  :options="existingFlags"
                  :taggable="true"
                  tag-placeholder="Add a tag"
                  placeholder="Select or add a tag"
                  @tag="addFlag"
                  label="name"
                  track-by="name"
                />
                <small class="text-danger">{{ $t(errors[0]) }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Set Flag Color">
              <b-dropdown
                id="colorDropdown"
                class="form-control form-dropdown"
                variant="link"
              >
                <template #button-content>
                  <p class="d-flex align-items-center" v-if="tag.tagColor">
                    <span
                      class="mr-1"
                      :style="{
                        backgroundColor: tag.tagColor,
                        width: '20px',
                        height: '20px',
                        display: 'inline-block',
                        borderRadius: '20px',
                      }"
                    >
                    </span>
                    {{ tag.tagColor || "None" }}
                  </p>
                  <p v-else>{{ $t("Select a color") }}</p>
                  <feather-icon icon="ChevronDownIcon" size="20" />
                </template>
                <div class="color-pettle">
                  <b-dropdown-item
                    v-for="color in colors"
                    :key="color.value"
                    @click="tag.tagColor = color.value"
                  >
                    <span
                      class="color-box"
                      :style="{
                        backgroundColor: color.value,
                      }"
                    >
                    </span>
                    <!-- {{ color.name }} -->
                  </b-dropdown-item>
                </div>
              </b-dropdown>
            </b-form-group>
            <div class="d-flex align-items-center justify-content-end mt-2">
              <b-button type="submit" variant="primary">{{
                $t("Save")
              }}</b-button>
            </div>
          </validation-observer>
        </b-form>
      </b-modal>
      <!---===================================-->
      <!---===================================-->
      <div class="c-modal" v-show="showPreviewModal">
        <div class="c-modal-content">
          <div class="c-modal-header">
            <h3>{{ $t("Preview") }}</h3>
            <div class="x-icon" @click="closePreviewModal">
              <feather-icon size="16" icon="XIcon" />
            </div>
          </div>
          <div class="c-modal-body">
            <PreviewModel
              v-if="showPreviewModal"
              :upper="currentUpper"
              :lower="currentLower"
            />
          </div>
        </div>
      </div>
      <!---===================================-->
      <!---===================================-->
      <div class="c-modal" v-show="showPreviewResultModal">
        <div class="c-modal-content">
          <div class="c-modal-header">
            <h3>{{ $t("Preview") }}</h3>
            <div class="x-icon" @click="closePreviewResultModal">
              <feather-icon size="16" icon="XIcon" />
            </div>
          </div>
          <div class="c-modal-body">
            <PreviewResultModel
              v-if="showPreviewResultModal"
              :results="results"
            />
          </div>
        </div>
      </div>
      <!---===================================-->
      <!---===================================-->
      <b-modal
        id="edit-tag-modal"
        v-model="showCreditModal"
        title="Not enough credits"
        centered
        size="md"
        hide-footer
      >
        {{ $t("You do not have enough credits to recalculate the case") }}
        <div class="d-flex align-items-center justify-content-end mt-2">
          <b-button @click="showCreditModal = false" variant="primary">{{
            $t("Ok")
          }}</b-button>
        </div>
      </b-modal>
      <!---===================================-->
      <!---===================================-->
    </div>
  </div>
</template>

<script>
import CookieButton from "@/components/elements/CookieButton.vue";
import ElementIcon from "@/components/elements/Icon.vue";
import { BLink, BRow, BCol, BImg, BPagination } from "bootstrap-vue";
import FileService from "@/services/file.service";
import { mapGetters } from "vuex";
import MultiSelect from "vue-multiselect";
import NProgress from "nprogress";
import PreviewModel from "../components/PreviewModel.vue";
import PreviewResultModel from "../components/PreviewResultModel.vue";
import PageHeader from "@/components/PageHeader.vue";
import { required, email } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import StarRating from "@/components/StarRating.vue";
import { debounce } from "@/utils/debounce.js";
export default {
  components: {
    ElementIcon,
    BRow,
    BCol,
    BLink,
    BImg,
    BPagination,
    MultiSelect,
    CookieButton,
    PreviewModel,
    PreviewResultModel,
    PageHeader,
    ValidationProvider,
    ValidationObserver,
    StarRating,
  },
  data() {
    return {
      selectedJaw: "both",
      showModal: false,
      showCreditModal: false,
      showPreviewModal: false,
      showPreviewResultModal: false,
      currentTag: {
        index: null,
        text: "",
        color: "#ffffff",
      },
      intervalId: null,
      previousItems: null,
      currentPage: 1,
      rows: [],
      selectedId: null,
      perPage: 6,
      selectAll: false,
      totalRecords: 0,
      credits: 0,
      selectedCases: [],
      tag: {
        flag: null,
        tagColor: "",
      },
      colors: [
        { name: "Red", value: "#ff0000" },
        { name: "Green", value: "#00ff00" },
        { name: "Blue", value: "#0000ff" },
        { name: "Yellow", value: "#ffff00" },
        { name: "Magenta", value: "#ff00ff" },
        { name: "Cyan", value: "#00ffff" },
        { name: "Maroon", value: "#800000" },
        { name: "Dark Green", value: "#008000" },
        { name: "Navy", value: "#000080" },
        { name: "Gray", value: "#808080" },
        { name: "Orange", value: "#ffa500" },
        { name: "Purple", value: "#800080" },
        { name: "Teal", value: "#008080" },
        { name: "Olive", value: "#808000" },
        { name: "Silver", value: "#c0c0c0" },
        { name: "Gold", value: "#ffd700" },
        { name: "Coral", value: "#ff7f50" },
        { name: "Salmon", value: "#fa8072" },
        { name: "Lavender", value: "#e6e6fa" },
        { name: "Sky Blue", value: "#87ceeb" },
        { name: "Slate Gray", value: "#708090" },
        { name: "Chocolate", value: "#d2691e" },
        { name: "Crimson", value: "#dc143c" },
        { name: "Periwinkle", value: "#ccccff" },
        { name: "Mint", value: "#98ff98" },
        { name: "Peach", value: "#ffcc99" },
        { name: "Ivory", value: "#fffff0" },
        { name: "Plum", value: "#dda0dd" },
        { name: "Turquoise", value: "#40e0d0" },
        { name: "Beige", value: "#f5f5dc" },
        { name: "Orchid", value: "#da70d6" },
        { name: "Tomato", value: "#ff6347" },
      ],
      form: {
        sortBy: "createdAt",
        sortOrder: "desc",
        search: "",
        status: "active",
        selectedFilter: "all",
        caseStatus: "all",
      },
      filteredCases: [],
      currentTag: {
        color: "",
      },
      costsPerUpperJaw: "",
      costsPerLowerJaw: "",
      recalculationFactor: "",
      existingFlags: [],
      currentUpper: null,
      currentLower: null,
      results: null,
      filterRatingValue: null,
      caseStatusOptions: [
        { value: "all", text: "All" },
        { value: "done", text: "Done" },
        { value: "failed", text: "Failed" },
        { value: "in-progress", text: "In Progress" },
        { value: "in-approval", text: "In Approval" },
        { value: "queued", text: "Queued" },
        { value: "unsupported", text: "Unsupported" },
      ],
    };
  },
  computed: {
    ...mapGetters("globalConfiguration", ["configuration"]),
    ...mapGetters("caseLists", ["reportCaseID"]),
    ...mapGetters(["showLoader"]),
    items() {
      return [
        {
          text: this.$t("Dental Twin"),
          to: "/home",
        },
        {
          text: this.$t("My Cases"),
          to: "/my-cases/active",
        },
        {
          text: this.$t("Active"),
          active: true,
        },
      ];
    },
  },
  async created() {
    this.debouncedFetch = debounce(async (newValue, oldValue) => {
      try {
        await this.loadItems();
      } catch (e) {
        console.error(e);
      }
    }, 300);
  },
  methods: {
    async setFilterRatingValue(event) {
      this.filterRatingValue = event;
      await this.loadItems();
    },
    async setRatingValue(event, id, jawType) {
      await this.$store.dispatch("caseLists/saveRating", {
        caseId: id,
        ...(jawType === "upper"
          ? { upperCaseRating: event }
          : { lowerCaseRating: event }),
      });
    },
    reportCase(reportedCase) {
      this.$store.commit("caseLists/reportCaseID", reportedCase);
      this.$router.push("/tickets/create");
    },
    handlePreviewClick(upper, lower) {
      this.currentUpper = upper;
      this.currentLower = lower;
      this.showPreviewModal = true;
    },
    handlePreviewResultClick(array) {
      this.results = array;
      this.showPreviewResultModal = true;
    },

    async getUpperImage(fileId, caseItem) {
      try {
        // Fetch the binary data as a Blob
        const response = await FileService.getResultById(fileId);

        // Create a FileReader to convert the Blob to Base64
        const reader = new FileReader();

        reader.onloadend = () => {
          // Get the Base64 string
          const base64data = reader.result;

          // Set the Base64 string to caseItem
          this.$set(caseItem, "upperImageSrc", base64data); // Use Vue's reactivity
        };

        // Read the Blob as a Data URL (Base64)
        reader.readAsDataURL(response.data);
      } catch (error) {
        console.error("Error fetching binary file:", error);
      }
    },
    async getLowerImage(fileId, caseItem) {
      try {
        // Fetch the binary data as a Blob
        const response = await FileService.getResultById(fileId);

        // Create a FileReader to convert the Blob to Base64
        const reader = new FileReader();

        reader.onloadend = () => {
          // Get the Base64 string
          const base64data = reader.result;

          // Set the Base64 string to caseItem
          this.$set(caseItem, "lowerImageSrc", base64data); // Use Vue's reactivity
        };

        // Read the Blob as a Data URL (Base64)
        reader.readAsDataURL(response.data);
      } catch (error) {
        console.error("Error fetching binary file:", error);
      }
    },
    dateFormatter(timeStamp) {
      // Convert to Date object
      const date = new Date(timeStamp);

      // Format the date (YYYY-MM-DD HH:mm:ss)
      const formattedDate = date.toISOString().split("T")[0];
      const formattedTime = date.toTimeString().split(" ")[0]; // Extract time in HH:mm:ss format

      // Combine date and time
      return `${formattedDate} ${formattedTime}`;
    },
    downloadFiles(array) {
      FileService.downloadFiles(array);
    },
    onPageChange(params) {
      this.page = params.currentPage;
      this.loadItems();
    },
    onPerPageChange(params) {
      this.updateParams({ pageLength: params.pageLength });
      this.loadItems();
    },
    hoverMessage(caseItem) {
      // Check if the caseStatus is 'failed' or 'unsupported' and if results JSON is not empty
      if (
        ["failed", "unsupported"].includes(caseItem.caseStatus) &&
        caseItem.results
      ) {
        return caseItem.results["message"];
      }
      return ""; // Return empty string if conditions are not met
    },

    onSortChange(params) {
      this.form.sortOrder = params[0].type;
      if (params[0].type == "none") this.form.sortOrder = "asc";
      this.form.sortBy = params[0].field;
      this.loadItems();
    },
    async loadFlags() {
      let response = await this.$store.dispatch("flags/list");
      this.existingFlags = response?.data?.data;
    },
    async updateCase() {
      this.$refs.simpleRules.validate().then(async (success) => {
        if (success) {
          await this.$store
            .dispatch("caseLists/updateFlag", {
              caseId: this.selectedId,
              tagColor: this.tag.tagColor ?? "#ffffff",
              flag: this.tag.flag?.id ?? null,
            })
            .then((res) => {
              this.loadItems();
              this.showModal = false;
            });
        }
      });
    },

    async loadItems() {
      const response = await this.$store.dispatch("caseLists/list", {
        page: this.page,
        ...this.form,
        perPage: this.pageLength,
        selectedFilter: this.form.selectedFilter,
        starRating: this.filterRatingValue,
      });

      // Make a deep copy of the current data to compare accurately
      const newItems = JSON.parse(JSON.stringify(response?.data?.data));
      // Compare with the previous items
      if (JSON.stringify(newItems) !== JSON.stringify(this.previousItems)) {
        // Update previousItems with a deep copy to avoid modifications
        this.previousItems = JSON.parse(JSON.stringify(newItems));
        this.rows = newItems;
        this.totalRecords = response?.data?.meta?.total;

        // Reload images only if necessary
        this.rows.forEach((caseItem) => {
          if (caseItem.results?.upper_image) {
            this.getUpperImage(caseItem.results.upper_image, caseItem);
          }
          if (caseItem.results?.lower_image) {
            this.getLowerImage(caseItem.results.lower_image, caseItem);
          }
        });
      }
    },

    async addFlag(newTag) {
      await this.$store.dispatch("flags/create", {
        name: newTag,
        color: this.tag.tagColor,
      });
      this.loadFlags();
    },
    async updateStatus(id, status) {
      const title =
        status === "trash"
          ? this.$t("Do you want to delete this record?")
          : this.$t("Do you want to update this record?");
      const subText =
        status === "trash"
          ? this.$t("Yes delete it!")
          : this.$t("Yes update it!");
      this.$swal({
        title: title,
        text: this.$t("You can't revert your action"),
        type: "warning",
        customClass: "custom-delete-popup",
        showCancelButton: true,
        confirmButtonText: subText,
        cancelButtonText: this.$t("No"),
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        if (result.isConfirmed === true) {
          await this.$store
            .dispatch("caseLists/updateStatus", {
              caseId: id,
              status: status,
            })
            .finally(() => {
              this.loadItems();
            });
        }
      });
    },
    async replicateCase(id, files) {
      var sumCredits = 0;
      if (files["upper"] != null) {
        sumCredits = parseFloat(this.costsPerUpperJaw);
      }
      if (files["lower"] != null) {
        sumCredits = sumCredits + parseFloat(this.costsPerLowerJaw);
      }
      if (
        sumCredits * parseFloat(this.recalculationFactor) >
        parseFloat(this.credits)
      ) {
        this.showCreditModal = true;
      } else {
        this.$swal({
          title: this.$t("Do you want to recalculate this record?"),
          text:
            this.$t("It will cost you ") +
            sumCredits * parseFloat(this.recalculationFactor) +
            " credits",
          type: "warning",
          customClass: "custom-delete-popup",
          showCancelButton: true,
          confirmButtonText: this.$t("Yes, recalculate it!"),
          cancelButtonText: this.$t("No"),
          showCloseButton: true,
          showLoaderOnConfirm: true,
        }).then(async (result) => {
          if (result.isConfirmed === true) {
            await this.$store
              .dispatch("caseLists/replicateCase", {
                caseId: id,
                extensions: [{ id: "opg-to-3d" }],
              })
              .finally(() => {
                this.getCredits();
                this.loadItems();
              });
          }
        });
      }
    },
    async updateStatuses(status) {
      // Set title based on the status
      const title =
        status === "trash"
          ? this.$t("Do you want to delete these records?")
          : this.$t("Do you want to update these records?");
      const subText =
        status === "trash"
          ? this.$t("Yes delete them!")
          : this.$t("Yes update them!");
      if (status === "trash") {
        this.$swal({
          title: title,
          text: this.$t("You can't revert your action"),
          type: "warning",
          customClass: "custom-delete-popup",
          showCancelButton: true,
          confirmButtonText: subText,
          cancelButtonText: this.$t("No"),
          showCloseButton: true,
          showLoaderOnConfirm: true,
        }).then(async (result) => {
          if (result.isConfirmed === true) {
            await this.$store
              .dispatch("caseLists/updateStatuses", {
                caseIds: this.selectedCases,
                status: status,
              })
              .finally(() => {
                this.selectedCases = [];
                this.loadItems();
              });
          }
        });
      } else {
        await this.$store
          .dispatch("caseLists/updateStatuses", {
            caseIds: this.selectedCases,
            status: status,
          })
          .finally(() => {
            this.selectedCases = [];
            this.loadItems();
          });
      }
    },
    async downloadCaseFiles() {
      var cases = this.rows.filter((row) =>
        this.selectedCases.includes(row.id)
      );
      // Loop through each case using forEach
      cases.forEach((caseItem) => {
        this.downloadFiles([caseItem.files["upper"], caseItem.files["lower"]]);
      });
      this.selectedCases = [];
    },
    openModal(caseItem) {
      this.selectedId = caseItem.id;
      this.tag.tagColor = caseItem.tagColor;
      this.tag.flag = caseItem.flag;
      this.showModal = true;
    },
    async getCredits() {
      const creditResponse = await this.$store.dispatch("customers/getCredits");
      this.credits = creditResponse?.data?.credits ?? 0;
    },
    toggleSelectAll() {
      if (this.selectAll) {
        // Map over rows to extract all IDs
        this.selectedCases = this.rows.map((row) => row.id);
      } else {
        this.selectedCases = [];
      }
    },
    closePreviewModal() {
      this.showPreviewModal = false;
    },
    closePreviewResultModal() {
      this.showPreviewResultModal = false;
    },
  },
  async mounted() {
    this.$store.commit("showLoader", true);
    this.loadItems();

    this.loadFlags();
    this.getCredits();

    await this.$store.dispatch("globalConfiguration/show");
    this.costsPerUpperJaw = this.configuration.costsPerUpperJaw ?? "";
    this.costsPerLowerJaw = this.configuration.costsPerLowerJaw ?? "";
    this.recalculationFactor = this.configuration.recalculationFactor ?? "";
    this.$store.commit("showLoader", false);
    this.intervalId = setInterval(() => {
      this.loadItems();
    }, 30000);
  },
  beforeDestroy() {
    // Clear the interval when the component is destroyed
    clearInterval(this.intervalId);
  },
  watch: {
    selectedCases(newVal) {
      this.selectAll = newVal.length === this.rows.length;
    },
    "form.search": function (val) {
      this.debouncedFetch(...val);
    },
    "form.selectedFilter": function (val) {
      this.debouncedFetch(...val);
    },
    "form.caseStatus": function (val) {
      this.debouncedFetch(...val);
    },
  },
  beforeRouteEnter(to, from, next) {
    document.body.classList.add("cookie-layout");
    next();
  },
  beforeRouteLeave(to, from, next) {
    document.body.classList.remove("cookie-layout");
    next();
  },
};
</script>
<style lang="scss">
.form-dropdown {
  width: 100%;
  .dropdown-toggle {
    padding: 0 !important;
    height: auto !important;
    color: #fff;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &::after {
      display: none;
    }
  }
}
</style>
