<template>
  <div>
    <PageHeader :items="items" :optionalItems="optionalItems">
      <template #action>
        <div class="search mr-2">
          <input
            type="text"
            v-model="form.search"
            class="form-control"
            :placeholder="$t('Search here.....')"
          />
        </div>
        <!-- <div class="sorting">
          <MultiSelectInput
            class="d-flex align-items-center m-0 gap-2"
            :label="'Sort by'"
            :placeholder="''"
          />
        </div> -->
      </template>
    </PageHeader>
    <div class="card">
      <div class="card-body">
        <b-row class="user-responsive-table">
          <b-col lg="6" md="12" class="left-col">
            <div class="custom-search d-flex justify-content-between mb-1">
              <b-form-group class="m-0">
                <div class="d-flex align-items-center">
                  <span class="text-nowrap"> {{ $t("Rows per page") }} </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['10', '25', '50', '100']"
                    class="ml-1"
                    @input="(value) => onPerPageChange({ pageLength: value })"
                  />
                </div>
              </b-form-group>
              <b-pagination
                :value="1"
                :total-rows="totalRecords"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => onPageChange({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
            <div class="api-keys-table">
              <!-- table -->
              <vue-good-table
                ref="goodTable"
                styleClass="vgt-table striped"
                :columns="columns"
                :fixed-header="false"
                :rows="modifiedUsers"
                :search-options="{
                  enabled: true,
                  externalQuery: searchTerm,
                  trigger: 'enter',
                }"
                :pagination-options="{
                  enabled: false,
                  perPage: pageLength,
                }"
                mode="remote"
                @on-page-change="onPageChange"
                @on-sort-change="onSortChange"
                @on-per-page-change="onPerPageChange"
              >
                <div
                  class="vgt-center-align vgt-text-disabled"
                  slot="emptystate"
                >
                  {{ $t("No data for table") }}
                </div>
                <template slot="table-row" slot-scope="props">
                  <!-- Column: Name -->
                  <div
                    v-if="props.column.field === 'name'"
                    class="px-auto cursor-pointer"
                  >
                    <span>{{
                      props.row.first_name + " " + props.row.last_name
                    }}</span>
                  </div>
                  <div
                    v-else-if="props.column.field === 'email'"
                    class="px-auto cursor-pointer"
                  >
                    <span>{{ props.row.email }}</span>
                  </div>
                  <span v-else-if="props.column.field === 'roles'">
                    <multi-select
                      v-model="props.row.roles"
                      label="title"
                      @select="
                        assignRoles(
                          $event,
                          props.row.roles,
                          props.row.id,
                          props.row
                        )
                      "
                      @remove="
                        removeRoles(
                          $event,
                          props.row.roles,
                          props.row.id,
                          props.row
                        )
                      "
                      track-by="id"
                      :options="roles"
                      :multiple="true"
                      :placeholder="$t('Select Option')"
                    >
                    </multi-select>
                  </span>
                  <div
                    v-else-if="props.column.field === 'check'"
                    class="d-flex align-items-center"
                  >
                    <div
                      v-if="
                        $can(`${$route.meta.permission}.create`) ||
                        $can(`${$route.meta.permission}.delete`)
                      "
                      class="checkbox-group"
                    >
                      <input
                        type="checkbox"
                        class="checkbox-input"
                        :id="'check' + props.row.id"
                        :value="props.row.id"
                        @change="toggleSelection(props.row.id)"
                        :checked="selectedRows.includes(props.row.id)"
                      />
                      <label
                        :for="'check' + props.row.id"
                        class="checkbox-label"
                      ></label>
                    </div>
                  </div>
                  <!-- Column: Common -->
                  <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                  </span>
                </template>
              </vue-good-table>
            </div>
            <div class="d-flex align-items-center justify-content-end mt-2">
              <button
                v-if="$can(`${$route.meta.permission}.delete`)"
                @click="deleteUnitUsers"
                :disabled="selectedRows.length == 0"
                class="btn btn-secondary mr-1"
              >
                {{ $t("Delete User") }}
              </button>
            </div>
          </b-col>
          <b-col
            class="right-col"
            lg="6"
            md="12"
            v-if="
              $can(`${$route.meta.permission}.create`) ||
              $can(`${$route.meta.permission}.edit`)
            "
          >
            <validation-observer ref="simpleRules">
              <div class="e-card">
                <div class="e-card-body">
                  <b-row>
                    <b-col lg="6">
                      <TextInput
                        :label="$t('First Name')"
                        :type="'text'"
                        v-model="userUnit.firstName"
                        :placeholder="''"
                      />
                    </b-col>
                    <b-col lg="6">
                      <TextInput
                        :label="$t('Last Name')"
                        :type="'text'"
                        v-model="userUnit.lastName"
                        :placeholder="''"
                      />
                    </b-col>
                    <b-col lg="12">
                      <div class="form-group">
                        <label
                          class="form-label input-label"
                          for="address_for_invoices"
                          >{{ $t("Role") }}</label
                        >
                        <multi-select
                          :multiple="true"
                          label="title"
                          track-by="id"
                          :options="roles"
                          v-model="userUnit.role"
                          :placeholder="$t('Select Option')"
                        />
                      </div>
                    </b-col>
                    <b-col lg="6">
                      <div class="form-group">
                        <label
                          class="form-label input-label"
                          for="address_for_invoices"
                          >{{ $t("Mobile phone number") }}</label
                        >
                        <div class="d-flex country-code-dropdown">
                          <multi-select
                            :options="countries"
                            track-by="dial_code"
                            v-model="userUnit.countryCode"
                            placeholder="Select"
                            :custom-label="customLabel"
                          >
                            <!-- Dropdown Options Template -->
                            <template #option="{ option }">
                              <span>
                                <img
                                  class="mr-1"
                                  :src="option.emoji"
                                  width="25"
                                  height="25"
                                  alt="flag"
                                />
                                {{ option.name }} {{ option.dial_code }}
                              </span>
                            </template>

                            <!-- Selected Option Template -->
                            <template #singleLabel="{ option }">
                              <span v-if="option">
                                <img
                                  :src="option.emoji"
                                  width="25"
                                  height="25"
                                  alt="flag"
                                />
                                {{ option.dial_code }}
                              </span>
                            </template>
                          </multi-select>
                          <TextInput
                            :type="'text'"
                            v-model="userUnit.mobileNo"
                            :placeholder="''"
                          />
                        </div>
                      </div>
                    </b-col>
                    <b-col lg="6">
                      <validation-provider
                        #default="{ errors }"
                        name="New Password"
                        :rules="editMode ? '' : 'required'"
                      >
                        <TextInput
                          :label="$t('New Password')"
                          :type="'password'"
                          v-model="user.password"
                          :required="!editMode"
                        />
                        <small class="text-danger">{{ $t(errors[0]) }}</small>
                      </validation-provider>
                    </b-col>
                    <b-col lg="6">
                      <validation-provider
                        #default="{ errors }"
                        name="Confirm Password"
                        :rules="editMode ? '' : 'required'"
                      >
                        <TextInput
                          :label="$t('Confirm Password')"
                          :type="'password'"
                          v-model="user.confirmPassword"
                          :required="!editMode"
                        />
                        <small class="text-danger">{{ $t(errors[0]) }}</small>
                      </validation-provider>
                    </b-col>

                    <b-col lg="12">
                      <validation-provider
                        #default="{ errors }"
                        name="Email"
                        rules="required|email"
                      >
                        <TextInput
                          :label="$t('Email')"
                          :type="'text'"
                          v-model="userUnit.email"
                          :placeholder="''"
                          :required="true"
                        />
                        <small class="text-danger">{{ $t(errors[0]) }}</small>
                      </validation-provider>
                    </b-col>
                    <b-col lg="12">
                      <div class="form-group">
                        <label
                          class="form-label input-label"
                          for="address_for_invoices"
                          >{{ $t("Organizational Unit") }}</label
                        >
                        <multi-select
                          :multiple="false"
                          label="unit"
                          track-by="id"
                          :options="units"
                          v-model="userUnit.unit"
                          :placeholder="$t('Select Option')"
                        />
                      </div>
                    </b-col>
                  </b-row>
                </div>
              </div>
              <div class="d-flex align-items-center justify-content-end mt-2">
                <button
                  @click="resetForm"
                  v-if="editMode"
                  class="btn btn-secondary mr-1"
                >
                  {{ $t("Close Edit Mode") }}
                </button>
                <button
                  v-if="editMode && $can(`${$route.meta.permission}.edit`)"
                  @click="storeUnitUser"
                  class="btn btn-primary mr-1"
                >
                  {{ $t("Update User") }}
                </button>
                <button
                  v-if="$can(`${$route.meta.permission}.create`) && !editMode"
                  @click="storeUnitUser"
                  class="btn btn-primary"
                >
                  {{ $t("Add User") }}
                </button>
              </div>
            </validation-observer>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import { mapGetters } from "vuex";
import NotificationService from "../../services/notification.service";
import MultiSelect from "vue-multiselect";
import countries from "@/assets/data/countries.json";
import TextInput from "@/components/TextInput.vue";
import MultiSelectInput from "@/components/MultiSelectInput.vue";
import { required, email } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { debounce } from "@/utils/debounce";
export default {
  components: {
    PageHeader,
    MultiSelect,
    TextInput,
    MultiSelectInput,
    ValidationProvider,
    ValidationObserver,
  },
  computed: {
    modifiedUsers() {
      return this.rows.map((row) => {
        return {
          ...row,
          roles: row.roles
            .map((roleId) => {
              return {
                ...(this.roles?.find((role) => role.id == roleId) ?? {}),
              };
            })
            .filter((role) => role.id),
        };
      });
    },
    ...mapGetters("roles", ["roles"]),
    ...mapGetters("units", ["units"]),
    ...mapGetters(["showLoader"]),
    columns() {
      return [
        {
          label: "",
          field: "check",
          sortable: false,
        },
        {
          tdClass: "vgt-center-align",
          thClass: "vgt-center-align white-color",
          label: this.$t("Name"),
          field: "name",
          sortable: false,
        },
        {
          tdClass: "vgt-center-align",
          thClass: "vgt-center-align white-color",
          label: this.$t("Email"),
          field: "email",
          sortable: false,
        },
        {
          tdClass: "vgt-center-align",
          thClass: "vgt-center-align white-color",
          label: this.$t("Role"),
          field: "roles",
          sortable: false,
        },
      ];
    },
    items() {
      return [
        {
          text: this.$t("Dental Twin"),
          to: "/home",
        },
        {
          text: this.$t("Settings"),
          to: "/settings",
        },
        {
          text: this.$t("User"),
          active: true,
        },
      ];
    },
    optionalItems() {
      return {
        createBtn1: {
          show: false,
          text: this.$t("Create User"),
          icon: "PlusIcon",
          path: "/users/create",
          permission: "user.create",
        },
      };
    },
  },
  data() {
    return {
      selectedRows: [], // Array to hold selected row IDs
      countries,
      totalRecords: 0,
      pageLength: 10,
      page: 1,
      form: {
        sortOrder: "",
        sortBy: "",
        search: "",
      },
      userUnit: {
        firstName: "",
        lastName: "",
        role: null,
        mobileNo: "",
        unit: null,
        countryCode: "",
        email: "",
      },
      originalUserUnit: {
        firstName: "",
        lastName: "",
        role: null,
        mobileNo: "",
        unit: null,
        countryCode: "",
        email: "",
      },
      rows: [],
      searchTerm: "",
      user: {
        password: "",
        confirmPassword: "",
      },
      editMode: false,
      userId: "",
      unitUserId: "",
      hasUnsavedChanges: false,
      timer: false,
    };
  },
  watch: {
    "form.search"(...val) {
      this.debouncedFetch(...val);
    },
    userUnit: {
      deep: true, // Ensures the watcher tracks changes to nested objects
      handler(newVal, oldVal) {
        this.hasUnsavedChanges =
          JSON.stringify(newVal) !== JSON.stringify(this.originalUserUnit);
      },
    },
  },

  async created() {
    this.addRowClickListeners();
    this.debouncedFetch = debounce(async (newValue, oldValue) => {
      try {
        await this.loadItems();
      } catch (e) {
        console.error(e);
      }
    }, 300);
    window.addEventListener("beforeunload", this.handleBeforeUnload);
    try {
      this.$store.commit("showLoader", true);
      await this.$store.dispatch("roles/list", {
        limit_start: 0,
        limit_count: 100,
      });
      await this.$store.dispatch("units/list");
      await this.loadItems();
      this.$store.commit("showLoader", false);
    } catch (e) {
      console.error(e);
    } finally {
      this.$store.commit("showLoader", false);
    }
  },
  updated() {
    this.addRowClickListeners();
  },
  destroyed() {
    window.addEventListener("beforeunload", this.handleBeforeUnload);
  },
  beforeRouteLeave(to, from, next) {
    if (this.hasUnsavedChanges) {
      const confirmLeave = window.confirm(
        "You have unsaved changes. Do you really want to leave?"
      );
      if (confirmLeave) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
  methods: {
    customLabel(option) {
      return `${option.name} ${option.dial_code}`;
    },
    addRowClickListeners() {
      this.$nextTick(() => {
        if (this.$refs.goodTable && this.$refs.goodTable.$el) {
          const rows = this.$refs.goodTable.$el.querySelectorAll(
            ".vgt-table tbody tr"
          );
          rows.forEach((row) => {
            row.classList.add("cursor-pointer");
          });
          rows.forEach((row, index) => {
            if (!row.dataset.listenerAttached) {
              row.addEventListener("click", (event) =>
                this.handleRowClick(event, index)
              );
              row.dataset.listenerAttached = true;
            }
          });
        } else {
          console.error("Vue-Good-Table is not rendered yet.");
        }
      });
    },
    handleRowClick(event, index) {
      event.preventDefault();
      const rowData = this.modifiedUsers[index];
      const clickedElement = event.target;
      if (
        clickedElement.tagName === "LABEL" ||
        clickedElement.tagName === "INPUT"
      ) {
        this.toggleSelection(rowData.id);
      }
      if (
        clickedElement.tagName === "TD" ||
        clickedElement.tagName === "SPAN"
      ) {
        if (this.$can(`${this.$route.meta.permission}.edit`)) {
          if (this.hasUnsavedChanges && this.editMode) {
            const confirmLeave = window.confirm(
              "You have unsaved changes. Do you really want to leave?"
            );
            if (confirmLeave) {
              this.switchToEditForm(rowData);
            }
          } else if (this.hasUnsavedChanges) {
            const confirmLeave = window.confirm(
              "You have unsaved changes. Do you really want to leave?"
            );
            if (confirmLeave) {
              this.switchToEditForm(rowData);
            }
          } else {
            this.switchToEditForm(rowData);
          }
        }
      } else {
        return;
      }
    },
    toggleSelection(rowId) {
      const index = this.selectedRows.indexOf(rowId);
      if (index > -1) {
        this.selectedRows.splice(index, 1);
      } else {
        this.selectedRows.push(rowId);
      }
    },
    handleBeforeUnload(event) {
      if (this.hasUnsavedChanges) {
        event.preventDefault();
        event.returnValue =
          "You have unsaved changes. Are you sure you want to leave?";
      }
    },
    async switchToEditForm(data) {
      this.$store.commit("showLoader", true);
      this.editMode = true;
      const unitResponse = await this.$store.dispatch("unitUsers/list", {
        userId: data?.id,
      });
      this.userUnit.firstName = data?.first_name ?? "";
      this.userUnit.lastName = data?.last_name ?? "";
      this.userUnit.role = data?.roles;
      this.userUnit.mobileNo = data?.mobile ?? "";
      this.userUnit.email = data?.email ?? "";
      this.userUnit.unit = unitResponse?.data?.data[0]?.unit ?? null;

      this.userUnit.countryCode =
        this.countries.find(
          (country) =>
            unitResponse?.data?.data[0]?.countryCode === country.dial_code
        ) ?? null;
      this.userUnit.mobileNo =
        unitResponse?.data?.data[0]?.mobileNo ?? data?.mobile ?? "";
      this.userId = data?.id ?? null;
      this.unitUserId = unitResponse?.data?.data[0]?.id ?? null;
      this.originalUserUnit = { ...this.userUnit };
      this.hasUnsavedChanges = false;
      this.$store.commit("showLoader", false);
    },
    async assignRoles(event, roles, id, user) {
      let arr = [];
      arr.push(event.id);
      roles.forEach((role) => {
        arr.push(role.id);
      });
      const payload = {
        id: id,
        roles: arr,
      };
      await this.$store.dispatch("users/update", {
        ...user,
        roles: payload.roles,
      });
    },
    async removeRoles(event, roles, id, user) {
      let arr = [];
      roles.forEach((role) => {
        arr.push(role.id);
      });
      arr.pop(event.id);
      const payload = {
        id: id,
        roles: arr,
      };
      await this.$store.dispatch("users/update", {
        ...user,
        roles: payload.roles,
      });
    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this, newProps);
    },
    async storeUnitUser() {
      try {
        this.$refs.simpleRules.validate().then(async (success) => {
          if (success) {
            if (this.user.password.length || this.user.confirmPassword.length) {
              if (this.user.password !== this.user.confirmPassword) {
                NotificationService.showError(
                  "Password and confirm password do not match"
                );
                return;
              }
            }
            if (!this.user.password.length) {
              delete this.user["password"];
              delete this.user["confirmPassword"];
            }
            const payload = {
              ...this.user,
              mail: this.userUnit.email,
              first_name: this.userUnit.firstName,
              last_name: this.userUnit.lastName,
              company_id: localStorage.getItem("company_id"),
              set_company_id: localStorage.getItem("company_id"),
              ...(this.userUnit?.role && {
                roles: (this.userUnit?.role ?? []).map((role) => role.id),
              }),
              mobile:
                (this.userUnit.countryCode?.dial_code ?? "") +
                (this.userUnit.mobileNo ?? ""),
            };
            this.$store.commit("isLoading", true);
            if (this.editMode) {
              await this.$store
                .dispatch("users/update", { id: this.userId, ...payload })
                .then((res) => {
                  this.store(res?.data?.id);
                });
            } else {
              await this.$store
                .dispatch("users/create", payload)
                .then((res) => {
                  this.store(res?.data?.id);
                });
            }
          }
        });
      } catch (e) {}
    },
    onPageChange(params) {
      this.page = params.currentPage;
      this.loadItems();
    },
    toggleSelection(rowId) {
      const index = this.selectedRows.indexOf(rowId);
      if (index > -1) {
        // If the ID is already selected, remove it
        this.selectedRows.splice(index, 1);
      } else {
        // If the ID is not selected, add it
        this.selectedRows.push(rowId);
      }
    },
    onPerPageChange(params) {
      this.updateParams({ pageLength: params.pageLength });
      this.loadItems();
    },

    onSortChange(params) {
      this.form.sortOrder = params[0].type;
      if (params[0].type == "none") this.form.sortOrder = "asc";
      this.form.sortBy = params[0].field;
      this.loadItems();
    },

    // load items is what brings back the rows from server
    async loadItems() {
      let response = await this.$store.dispatch("users/list", {
        limit_start: this.pageLength * (this.page - 1),
        limit_count: this.pageLength,
        search_string: this.form.search,
      });
      this.rows = response?.data?.data;
      this.totalRecords = response?.data?.count;
    },
    async store(userId) {
      if (this.editMode && this.unitUserId) {
        await this.$store.dispatch("unitUsers/update", {
          id: this.unitUserId,
          data: {
            ...this.userUnit,
            userId: userId,
            unitId: this.userUnit?.unit?.id ?? null,
            countryCode: this.userUnit?.countryCode?.dial_code ?? "",
          },
        });
      } else {
        await this.$store.dispatch("unitUsers/create", {
          ...this.userUnit,
          userId: userId,
          unitId: this.userUnit?.unit?.id ?? null,
          countryCode: this.userUnit?.countryCode?.dial_code ?? "",
        });
      }
      this.resetForm();
      this.loadItems();
    },
    resetForm() {
      this.$refs.simpleRules.reset();
      this.userUnit = {
        firstName: "",
        lastName: "",
        role: null,
        mobileNo: "",
        unit: null,
        countryCode: null,
        email: "",
      };
      this.user = {
        password: "",
        confirmPassword: "",
      };
      this.userId = "";
      this.unitUserId = "";
      this.editMode = false;
      this.originalUserUnit = { ...this.userUnit };
      this.hasUnsavedChanges = false;
    },
    async deleteUnitUsers() {
      this.$swal({
        title: this.$t("Do you want to delete these record?"),
        text: this.$t("You can't revert your action"),
        type: "warning",
        customClass: "custom-delete-popup",
        showCancelButton: true,
        confirmButtonText: this.$t("Yes delete it!"),
        cancelButtonText: this.$t("No"),
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        if (result.isConfirmed === true) {
          this.$store.commit("showLoader", true);
          await this.$store.dispatch("unitUsers/deleteUnitUsers", {
            ids: this.selectedRows,
          });
          await this.$store
            .dispatch("users/destroy", { id: this.selectedRows })
            .finally(() => {
              this.$store.commit("showLoader", false);
              this.selectedRows = [];
              this.loadItems();
            });
        }
      });
    },
  },
};
</script>

<style>
#nprogress {
  position: relative;
  z-index: 9999999;
}

.white-color {
  color: white !important;
}

.vgt-responsive {
  overflow-x: visible !important;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.country-code-dropdown {
  .multiselect {
    width: 200px;
    .multiselect__content-wrapper {
      width: 340px;
    }
    .multiselect__tags {
      border-bottom-right-radius: 0px;
      border-top-right-radius: 0px;
    }
  }
  input {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    height: 42.3px;
  }
}
</style>
